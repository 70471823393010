import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import { MyArchivedBomsComponent } from './my-archived-boms/my-archived-boms.component';
import { BomExplorerComponent } from './bom-explorer/bom-explorer.component';
import { BomCompareComponent } from './bom-compare/bom-compare.component';
import { SelectBomComponent } from './shared/select-bom/select-bom.component';
import { SelectBomPopupComponent } from './shared/select-bom-popup/select-bom-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    MyArchivedBomsComponent,
    BomExplorerComponent,
    BomCompareComponent,
    SelectBomComponent,
    SelectBomPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    /*start addition*/
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,

    // Import the HTTP client. 
    HttpClientModule,

    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration. 
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read']
        }
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          // [protectedResources.todoListApi.endpoint, protectedResources.todoListApi.scopes]
          ["https://graph.microsoft.com/v1.0/me", ['user.read']]
        ])
      })

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }
