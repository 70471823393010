import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ProfileService, ProfileType } from './profile.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {
  profile: ProfileType;

  constructor(private http: HttpClient
              , private profileSvc: ProfileService) { }

  ngOnInit(): void {
    this.getProfile();    
  }

  getProfile(){
    this.profileSvc.getProfile()
      .subscribe({
        next: (profile) => {
              this.profile = profile;
              console.log(`Profile Name is : ${this.profile.givenName} ${this.profile.surname}`)
            }
      });
  }
}

export class Claim {
  id: number;
  claim: string;
  value: string;
}
