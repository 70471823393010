import { Component } from '@angular/core';

@Component({
  selector: 'app-select-bom',
  templateUrl: './select-bom.component.html',
  styleUrl: './select-bom.component.css'
})
export class SelectBomComponent {

}
