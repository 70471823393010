import { Component } from '@angular/core';

@Component({
  selector: 'app-my-archived-boms',
  templateUrl: './my-archived-boms.component.html',
  styleUrl: './my-archived-boms.component.css'
})
export class MyArchivedBomsComponent {

}
