import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { MyArchivedBomsComponent } from './my-archived-boms/my-archived-boms.component';
import { BomExplorerComponent } from './bom-explorer/bom-explorer.component';
import { BomCompareComponent } from './bom-compare/bom-compare.component';

const routes: Routes = [
  {path: 'my-archived-boms', component: MyArchivedBomsComponent}
  ,{path: 'bom-explorer', component: BomExplorerComponent}
  ,{path: 'bom-compare', component: BomCompareComponent}
  ,{path: 'profile', component: ProfileComponent,canActivate: [MsalGuard]}
  ,{path: '', component: HomeComponent}  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation:
      ! BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
      ? 'enabledNonBlocking'
      : 'disabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
