<div class="row">
    <div class="col-md-6 card  pb-2 mt-3">
        <h2>BOM 1 Selection</h2>
        <app-select-bom></app-select-bom>
        <hr>
    </div>
    <div class="col-md-6 card  pb-2 mt-3">
        <h2>BOM 2 Selection</h2>
        <app-select-bom></app-select-bom>
        <hr>
    </div>
</div>
