import { Component, Inject, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProfileService, ProfileType } from './profile/profile.service';
import { environment } from '../environments/environment.dev';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
/* Changes start here. */
export class AppComponent implements OnInit{
  profile: ProfileType = {};
  profileName = '';
  title = 'BOM ANALYZER';
  loginDisplay = false;
  environmentName = environment.name;
  
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
              , private broadcastService: MsalBroadcastService
              , private authService: MsalService
              , private profileService: ProfileService) { }

  ngOnInit() {    

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    });
    
  }

  login() {
    console.log('trying to login')
    if (this.msalGuardConfig.authRequest){
      console.log('If block start')
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      console.log('Else Block')
      this.authService.loginRedirect();
    }
    console.log('Login Ended');
  }

  logout() { 
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    console.log('login display is '  + this.loginDisplay);
        
    this.profileService.getProfile()
    .subscribe({
      next: (profile) => {
        console.log(profile);
        this.profile = profile;
        this.profileName = this.profile.givenName + '  ' + this.profile.surname;}
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
