import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../environments/environment.dev';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
// export const b2cPolicies = {
//      names: {
//          signUpSignIn: "b2c_1_susi_reset_v2",
//          editProfile: "b2c_1_edit_profile_v2"
//      },
//      authorities: {
//          signUpSignIn: {
//              authority: "https://polarisind.b2clogin.com/polarisind.onmicrosoft.com/b2c_1_susi_reset_v2",
//          },
//          editProfile: {
//              authority: "https://polarisind.b2clogin.com/polarisind.onmicrosoft.com/b2c_1_edit_profile_v2"
//          }
//      },
//      authorityDomain: "polarisind.b2clogin.com"
//  };
 
 
export const msalConfig: Configuration = {
     auth: {
         clientId: environment.clientId,
         authority: 'https://login.microsoftonline.com/85f78c4c-ad11-4735-9624-0b2c11611dff',
        //  knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: environment.redirectUri, 
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
        //  storeAuthStateInCookie: isIE, 
     },
     system: {
        allowNativeBroker: false, 
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }         
    }
 }

// export const protectedResources = {
//   todoListApi: {
//     endpoint: "http://localhost:4200/api/todolist",
//     scopes: ["https://polarisind.onmicrosoft.com/api/tasks.read"],
//   },
// }
export const loginRequest = {
  scopes: []
};