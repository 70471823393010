export const environment = {
    name: 'dev',
    key: 'Development',

    clientId: 'd85f68ed-17d0-4459-842a-129800276b1e',
    // redirectUri: 'http://localhost:4200'
    redirectUri: 'https://bomanalyzer-dev.polarisapplications.com',
    postLogoutRedirectUri:'https://bomanalyzer-dev.polarisapplications.com'

};
