<div class="banner">
  <div class="polaris-logo">
    <img src="/assets/images/Polaris-Logo-White.png" alt="Polaris Logo">
  </div>
  <div class="brandName" style="text-align: center;">
    <a href="/" class="title">{{title}}</a><p class="environment mx-2" *ngIf="environmentName != 'prd'">{{environmentName}}</p>
  </div>

  <div class="banner-buttons">    
    <a mat-button [routerLink]="['profile']">{{profileName}}</a>
    <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
    <button mat-raised-button *ngIf="loginDisplay" (click)="logout()">Logout</button>
  </div>
</div>
<nav>
  <div class="links">
    <div class="link">
      <a href="/my-archived-boms">My Archived BOMs</a>
      <a href="/bom-explorer">BOM Explorer</a>
      <a href="/bom-compare">BOM Compare</a>
      <a href="https://polarisind.sharepoint.com/sites/eng_bomanalyzer/Documents/Forms/AllItems.aspx" target="_blank">Help</a>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>