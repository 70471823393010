<div class="select-bom-box ">    
    <div class="col-md-5"> 
        <div class="first-box">
            <label >BOM</label>
            <input class="form-control"  type="text" >
            <a class="btn btn-warning" style="width: fit-content;">Select BOM</a>
        </div>       
    </div>
    <div class="col-md-4 ">
        <div class="second-box ">
            <label >Effectivity Date</label>
            <input class="form-control" type="date">
        </div>
    </div>
</div>