import { Component } from '@angular/core';

@Component({
  selector: 'app-select-bom-popup',
  templateUrl: './select-bom-popup.component.html',
  styleUrl: './select-bom-popup.component.css'
})
export class SelectBomPopupComponent {

}
