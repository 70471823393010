import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'

export type ProfileType = {
  givenName? : string,
  surname? : string,
  userPrincipalName? : string,
  id? : string
};

@Injectable({
  providedIn: 'root'
})

export class ProfileService implements OnInit {
  profile: ProfileType;
  profileName = '';
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    console.log('Profile service initiated.')
  }


  getProfile(){
    console.log('Profile Service --- getting profile!')
    return this.http.get(GRAPH_ENDPOINT);
  }

  // getProfileName(){
  //   console.log('getting profile name')
  //   this.getProfile()
  //     .subscribe({
  //       next: (profile) => this.profile = profile
  //     });
  // console.log('profile name is '+ this.profileName)
  //   return this.profileName;
  // }
}
